import { Routes, Route, useLocation } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import AboutUs from "./pages/AboutUs";
import LEDD2 from "./pages/LEDD2";
import LEDD from "./pages/LEDD";
import Contact from "./pages/Contact";
import Press from "./pages/Press";
import ScientificResearch from "./pages/ScientificResearch";
import Grants from "./pages/Grants";
import F1 from "./pages/F1";
import F21 from "./pages/F21";
import F3 from "./pages/F3";
import F4 from "./pages/F4";
import F5 from "./pages/F5";
import Report from "./pages/Report";
function App() {
  const location = useLocation();
  const showNavAndFooter = location.pathname !== "/Report";
  return (
    <div className="main-container">
      {showNavAndFooter && (
        <div className="navbar">
          <Nav />
        </div>
      )}

      <div className="content">
        <Routes>
          <Route path="/" element={<AboutUs />} />
          <Route path="/ledd2" element={<LEDD2 />} />
          <Route path="/ledd" element={<LEDD />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/press" element={<Press />} />
          <Route path="/scientific-research" element={<ScientificResearch />} />
          <Route path="/grants" element={<Grants />} />
          <Route path="/F1" element={<F1 />} />
          <Route path="/F21" element={<F21 />} />
          <Route path="/F3" element={<F3 />} />
          <Route path="/F4" element={<F4 />} />
          <Route path="/F5" element={<F5 />} />
          <Route path="/Report" element={<Report />} />
        </Routes>
      </div>

      {showNavAndFooter && (
        <div className="footer">
          <Footer />
        </div>
      )}
    </div>
  );
}
export default App;
