import React from 'react';
import styles from "./EvaluationLine.module.css";

const EvaluationLine = ({ text,  notes, setNotes, isReadOnly}) => {

  const handleNotesChange = (e) => {
    if (!isReadOnly) { 
      setNotes(e.target.value);
    }
  };

  return (
    <div className={styles.group}>
      <div className={styles.text}>
        <span>{text}</span>
      </div>
      <textarea
        className={styles.notesTextArea}
        value={notes}
        onChange={handleNotesChange} 
        placeholder="Notes*"
        readOnly={isReadOnly}
      />
    </div>
  );
};

export default EvaluationLine;