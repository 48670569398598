import styles from "./UploadImageButton.module.css";
import React, { useState } from 'react';

const UploadImageButton = ({ text, onImageChange, image }) => {
 
  return (
    <div className={styles.rectangleParent} onClick={() => document.getElementById(`file-input-${text}`).click()}>
      {image ? (
        <img src={image} alt="Uploaded" className={styles.rectangleParent} />
      ) : (
        <>
          <div className={styles.instanceChild} />
          <div className={styles.instanceItem} />
          <div className={styles.groupParent}>
            <img className={styles.groupIcon} alt="" src="/group@2x.png" />
            <div className={styles.upload}>
              <p className={styles.standardUs}>Upload</p>
              <p className={styles.standardUs}>{text}</p>
            </div>
          </div>
        </>
      )}
        <input type="file" id={`file-input-${text}`} onChange={onImageChange} style={{ display: 'none' }} />
    
    </div>
  );
};


export default UploadImageButton;
