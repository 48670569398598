import "./Grants.css";
const Grants = () => {
  return (
    <div className="grants">
      <img alt="" src="/page/Grants.png"
        style={{
          display: "block",
          width: "100%"
        }} />
    </div>
  );
};

export default Grants;
