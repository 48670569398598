import styles from "./RegisterButton.module.css";

const RegisterButton = ({ text }) => {
  const handleClick = () => {
    console.log("Register button clicked");
  };

  return (
    <button className={styles.registerButton} onClick={handleClick}>
      <img className={styles.buttonBackground} alt="" src="/rectangle-24@2x.png" />
      <div className={styles.buttonContent}>
        <img className={styles.buttonIcon} alt="" src="/registerIcon.png" />
        <div className={styles.buttonText}>{text}</div>
      </div>
    </button>
  );
};

export default RegisterButton;
