import "./ScientificResearch.css";
const ScientificResearch = () => {
  return (
    <div className="scientific-research">
     <img alt="" src="/page/Research.png"
        style={{
          top:"2vh",
          display: "block",
          width: "100%"
        }} />
    </div>
  );
};

export default ScientificResearch;
