import { useCallback } from "react";
import ReadMoreButton from "../components/ReadMoreButton";
import "./LEDD.css";
const LEDD = () => {
  const onReadMoreButtonClick = useCallback(() => {
    window.open("/ledd2");
  }, []);

  return (
    <div className="ledd1">
       <img alt="" src="/page/LEDD.png"
        style={{
          display: "block",
          width: "100%"
        }} />

      <div style={{
        display: "flex",  
        alignItems: "center",  
        width: "100%",
        justifyContent: "space-evenly",  
      }}>
        <h1
        style={{
          margin: "0",
          fontSize: "44px",
          fontFamily: "Poppins",
          lineHeight: "130%",
          fontWeight: "600",
          fontFamily: "inherit",
          textAlign: "left",
          color: "white"
        }}
      >
          <p style={{ margin: "0", fontFamily: "Poppins"}}>For more details,</p>
          <p style={{ margin: "0", fontFamily: "Poppins"}}>just keep reading</p>
        </h1>
        <ReadMoreButton
          buttonText="Read More"
          buttonLinkText="/vuesaxlineararrowright.svg"
          onReadMoreButtonClick={onReadMoreButtonClick}
        />
      </div>
    </div>
  );
};

export default LEDD;
