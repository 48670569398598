import styles from "./ClinicalDiagnosisContainer.module.css";

const ClinicalDiagnosisContainer = ({ diagnosisText,diagnosisValue}) => {


  return (
    <div className={styles.container} >
      <div className={styles.clinicalDiagnosis}>{diagnosisText}</div>
      <i className={styles.notes} >{diagnosisValue}</i>
    </div>
  );
};

export default ClinicalDiagnosisContainer;
