import styles from "./DateField.module.css";

const DateField = ({ placeholder, name, value, onChange, readonly }) => {
  return (
    <div className={styles.dateInputWrapper}>
      <input 
        type="date" 
        className={styles.dateInput} 
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        readOnly={readonly} 
      />
    </div>
  );
};

export default DateField;
