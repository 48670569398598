import styles from "./Page2.module.css";
import React, { useState, useEffect } from 'react';

const Page2 = ({ imageType, station, currentPage, totalPages }) => {
    const defaultImageUrl = "/iamgePlaceholder.svg";
    const [processedImageUrl, setProcessedImageUrl] = useState(defaultImageUrl);
    const [originalImageUrl, setOriginalImageUrl] = useState(defaultImageUrl);

    useEffect(() => {
        const loadImageAsDataURL = (url, setter) => {
            if (!url || url === defaultImageUrl) {
                setter(defaultImageUrl);
                return;
            }

            const apiUrl = `https://function-aimed-backend.azurewebsites.net/api/ServeImage?imageName=${url}`;

            fetch(apiUrl)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setter(reader.result.toString());
                    };
                    reader.readAsDataURL(blob);
                }).catch(() => setter(defaultImageUrl));
        };

        const processedUrl = getImageUrl(true);
        const originalUrl = getImageUrl(false);

        loadImageAsDataURL(processedUrl, setProcessedImageUrl);
        loadImageAsDataURL(originalUrl, setOriginalImageUrl);
    }, [station, imageType]);

    const getImageUrl = (processed) => {
        switch (imageType) {
            case 'Doppler':
                return processed ? station.resultDopplerURL : station.originalDopplerURL;
            case 'Standard':
                return processed ? station.resultStandardURL : station.originalStandardURL;
            case 'Elasto':
                return processed ? station.resultElastoURL : station.originalElastoURL;
            default:
                return defaultImageUrl;
        }
    };

    return (
        <div className={styles.page} id="page">
            <div className={styles.antet}>
                <img className={styles.antetBgIcon} alt="" src="/antet-bg@2x.png" />
                <img className={styles.logoIcon} alt="" src="/LymphMedLogo.png" />
            </div>
            <div className={styles.content} >
                <div className={styles.reportHeader}>
                    <div className={styles.reportLine} />
                    <div className={styles.patientInfo}>
                        <div className={styles.patientSerialNumberContainer}>
                            <span>{`Patient Serial Number: `}</span>
                            <b>{station?.patientSN}</b>
                        </div>
                        <div className={styles.dateDdMmContainer}>
                            <span>{`Date: `}</span>
                            <b>{`${station.timestamp.split('T')[0]} ${station.timestamp.split('T')[1].split('.')[0]}`}</b>
                        </div>
                    </div>
                    <div className={styles.reportLine} />
                </div>

                <div className={styles.heading2}>{imageType}</div>

                <div className={styles.reportLineLight} />

                {imageType === 'Doppler' && (
                    <>
                        <div className={styles.line}>
                            <div className={styles.halfLine2}>
                                <div className={styles.halfLine2Text}>Vascularity Ratio:</div>
                                <div className={styles.halfLine2Value}>{station.vascularityRatio}</div>
                            </div>
                            <div className={styles.halfLine2}>
                                <div className={styles.halfLine2Text}>Vascularity Position:</div>
                                <div className={styles.halfLine2Value}>{station.vascularityPosition}</div>
                            </div>
                        </div>

                    </>
                )}
                {imageType === 'Elasto' && (
                    <>
                        <div className={styles.line}>
                            <div className={styles.halfLine3}>
                                <div className={styles.halfLine3Text}>Red:</div>
                                <div className={styles.halfLine3Value}>{station.red}</div>
                            </div>
                            <div className={styles.halfLine3}>
                                <div className={styles.halfLine3Text}>Green:</div>
                                <div className={styles.halfLine3Value}>{station.green}</div>
                            </div>
                            <div className={styles.halfLine3}>
                                <div className={styles.halfLine3Text}>Blue:</div>
                                <div className={styles.halfLine3Value}>{station.blue}</div>
                            </div>
                        </div>
                    </>
                )}

                {imageType === 'Standard' && (
                    <>

                        <div className={styles.heading3}>Shape parameters:</div>
                        <div className={styles.reportLineLight} />

                        <div className={styles.line}>
                            <div className={styles.halfLine1}>
                                <div className={styles.halfLine1Text}>Width:</div>
                                <div className={styles.halfLine1Value}>{station.width}</div>
                                <div className={styles.halfLine1Text}>Length:</div>
                                <div className={styles.halfLine1Value}>{station.length}</div>
                            </div>
                            <div className={styles.halfLine2}>
                                <div className={styles.halfLine2Text}>Contour Variability:</div>
                                <div className={styles.halfLine2Value}>{station.variability}</div>
                            </div>
                        </div>

                        <div className={styles.reportLineLight} />
                        <div className={styles.line}>
                            <div className={styles.halfLine1}>
                                <div className={styles.halfLine1Text}>Area:</div>
                                <div className={styles.halfLine1Value}>{station.area}</div>
                                <div className={styles.halfLine1Text} />
                                <div className={styles.halfLine1Value} />
                            </div>
                            <div className={styles.halfLine2}>
                                <div className={styles.halfLine2Text}>Contour Sharpness:</div>
                                <div className={styles.halfLine2Value}>{station.sharpness}</div>
                            </div>
                        </div>


                    </>
                )}
                <div className={styles.reportLineLight} />
                <div className={styles.header1}>Original {imageType} image:</div>
                <div className={styles.reportLine} />

                <img className={styles.image} loading="eager" alt="" src={originalImageUrl} />

                <div className={styles.reportLine} />
                <div className={styles.header1}>Processed {imageType} image:</div>
                <div className={styles.reportLine} />


                <img className={styles.image} loading="eager" alt="" src={processedImageUrl} />
                <div className={styles.reportLine} />
                <div className={styles.credit}> ©2023-2024 LymphMed-AI RO, All Rights Reserved </div>
                <div className={styles.pageNr}>{currentPage}/{totalPages}</div>
            </div>
        </div>
    );
};

export default Page2;
