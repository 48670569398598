import styles from "./GroupComponent.module.css";

const GroupComponent = ({ text, value }) => {

  return (
    <div className={styles.line}>
    <div className={styles.description} >
      {text}
    </div>
      <div className={styles.valueWrap} >
        <i className={styles.value} >
          {value}
        </i>
      </div>
    </div>
  );
};

export default GroupComponent;
