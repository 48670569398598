import styles from "./ResultCard.module.css";
import GroupComponent from "../components/Groups/GroupComponent";
import GroupComponent1 from "../components/Groups/GroupComponent1";
import GroupComponent2 from "../components/Groups/GroupComponent2";


const ResultCard = ({station}) => {
  console.log(station);
  return (
    <div className={styles.container}>
      <div className={styles.groupParent}>
        <div className={styles.parent2}>
          <div className={styles.header}>Standard US:</div>
          <div className={styles.shapeParameters}>Shape parameters:</div>
          <GroupComponent1 text="Width" value={station?.width} />
          <GroupComponent1  text="Length" value={station?.length}  />
          <GroupComponent1 text="Area" value={station?.area} />
          <GroupComponent text="Contour Variability" value={station?.variability} />
          <GroupComponent text="Contour sharpness" value={station?.sharpness}  />
          {/* <GroupComponent text="Shape type" value={station?.shapeType} /> */}
        </div>
        <div className={styles.parent}>
          <div className={styles.header}>Doppler US:</div>
          <GroupComponent text="Vascularity ratio" value={station?.vascularityRatio}  />
          <GroupComponent text="Vascularity position" value={station?.vascularityPosition}  />
        </div>
        <div className={styles.parent}>
          <div className={styles.header}>Elasto US:</div>
          <GroupComponent1 text="Blue" value={station?.red} />
          <GroupComponent1 text="Red" value={station?.green}/>
          <GroupComponent1 text="Green" value={station?.blue} />
        </div>
      </div>
      {/* <div className={styles.vectorParent}>
        <img className={styles.ellipseIcon} alt="" src="/ellipse-3@2x.png" />
        <b className={styles.b}>00</b>
        <b className={styles.b1}>%</b>
        <div className={styles.finalScore}>FINAL SCORE</div>
        <i className={styles.malignityProbability}>(malignity probability)</i>
      </div> */}
    </div>
  );
};

export default ResultCard;
