import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ReadMoreButton from "../components/ReadMoreButton";
import "./AboutUs.css";
const AboutUs = () => {
  const navigate = useNavigate();

  const onReadMoreButtonClick = useCallback(() => {
    navigate("/contact");
  }, [navigate]);

  return (
    <div className="about-us" >
      <img alt="" src="/page/aboutUs.png"
        style={{
          display: "block",
          width: "100%"
        }} />

      <div style={{
        display: "flex",  
        alignItems: "center",  
        width: "100%",
        justifyContent: "space-evenly",  
      }}>
        <h1
        style={{
          margin: "0",
          fontFamily: "Poppins",
          lineHeight: "130%",
          fontWeight: "600",
          fontFamily: "inherit",
          textAlign: "left",
          color: "white"
        }}
      >
          <p style={{ margin: "0", fontFamily: "Poppins"}}>Are you interested in</p>
          <p style={{ margin: "0", fontFamily: "Poppins"}}>working with us?</p>
        </h1>
        <ReadMoreButton
          buttonText="Let’s talk"
          buttonLinkText="/vuesaxlineararrowright.svg"
          onReadMoreButtonClick={onReadMoreButtonClick}
        />
      </div>
    </div>
  );
};

export default AboutUs;
