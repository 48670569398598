import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateField from "../Fields/DateField";
import Dropdown from "../Dropdowns/Dropdown";
import TextField from "../Fields/TextField";
import FirstTab from "../Tabs/FirstTab";
import SecondTab from "../Tabs/SecondTab";
import RegisterButton from "../Buttons/RegisterButton";
import GoToPatientButton from "../Buttons/GoToPatientButton";
import styles from "./PatientForm.module.css";
import { createPatient, getPatient } from '../../api';

const PatientForm = () => {
  const [activeTab, setActiveTab] = useState('first');
  const [patientData, setPatientData] = useState({
    dateOfBirth: '',
    gender: '',
    serialNumber: '',
    lastName: '',
    firstName: '',
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setPatientData({ ...patientData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let newErrors = {};
    if (activeTab === 'first') {
      if (!patientData.dateOfBirth) newErrors.dateOfBirth = 'Date of birth is required';
      if (!patientData.gender) newErrors.gender = 'Gender is required';
      if (!patientData.serialNumber) newErrors.serialNumber = 'Serial number is required';
      if (!patientData.lastName) newErrors.lastName = 'Last name is required';
      if (!patientData.firstName) newErrors.firstName = 'First name is required';
    } else if (activeTab === 'second') {
      if (!patientData.serialNumber) newErrors.serialNumber = 'Serial number is required';
    }
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      try {
        if (activeTab === 'first') {

          var apiResponse = await createPatient(
            patientData.firstName,
            patientData.lastName,
            patientData.serialNumber,
            patientData.gender,
            patientData.dateOfBirth
          );

          if (apiResponse !== undefined) {
            setErrors({ apiError: apiResponse });
          } else {
            navigate('/F3', { state: { serialNumber: patientData.serialNumber } });
          }
        } else if (activeTab === 'second') {

          const response = await getPatient(patientData.serialNumber);
          
          if (response) {
            navigate('/F3', { state: { serialNumber: patientData.serialNumber } });
          } else {
            setErrors({ ...errors, notFound: 'Patient not found' });
          }
        }
      } catch (error) {
        setErrors({ ...errors, apiError: 'An error occurred during the API call' });
      }
    } else {
      setErrors(formErrors);
    }
  };


  return (
    <form className={styles.patientform} onSubmit={handleSubmit}>
      <div style={{ position: 'absolute', width: '70%', height: '13%',display:"flex"}}>
          <FirstTab text="New Patient" isActive={activeTab === 'first'} icon="/Group37.svg" onClick={() => { setActiveTab('first'); }} />
          <SecondTab text="Search Patient" isActive={activeTab === 'second'} icon="/Group38.svg" onClick={() => { setActiveTab('second'); }} />
        </div>
        
      {activeTab === 'first' ? (

        <div className={styles.formBody}>
          <img className={styles.frameChild} alt="" src="/rectangle-3@2x.png" />
          <div className={styles.formfields}>
            <div className={styles.ageSex}>

              <DateField
                placeholder="DD/MM/YY*"
                name="dateOfBirth"
                value={patientData.dateOfBirth}
                onChange={handleInputChange} />

              <Dropdown
                placeholder="Select Gender*"
                name="gender"
                value={patientData.gender}
                onChange={handleInputChange}
                options={[
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" }
                ]}
              />

            </div>
            <TextField placeholder="Patient Serial Number*" value={patientData.serialNumber} name="serialNumber" onChange={handleInputChange} />
            <TextField placeholder="Last Name*" patientSNTop="3.44rem" value={patientData.lastName} name="lastName" onChange={handleInputChange} />
            <TextField placeholder="First Name*" patientSNTop="-0.06rem" value={patientData.firstName} name="firstName" onChange={handleInputChange} />

          </div>
          <RegisterButton text="Register New Patient" />
        </div>
      ) : (
        <div className={styles.formBody}>
          <img className={styles.frameChild} alt="" src="/rectangle-3@2x.png" />
          <div className={styles.formfields}>
            <img className={styles.searchicon} alt="" src="/searchicon@2x.png" />
            <TextField placeholder="Insert Patient Serial Number*" value={patientData.serialNumber} name="serialNumber" onChange={handleInputChange} />
          </div>
          <GoToPatientButton text="Go To Patient" />
        </div>
      )}
      <div className={styles.errorSection}>
        {Object.keys(errors).length > 0 && (
          <ul>
            {Object.keys(errors).map((errorKey, index) => (
              <li key={index}>{errors[errorKey]}</li>
            ))}
          </ul>
        )}
      </div>
    </form>

  );
};

export default PatientForm;