import ClinicalDiagnosisContainer from "./ClinicalDiagnosisContainer";
import ReportLine from "./ReportLine";
import styles from "./Page1.module.css";
import React from 'react';

const allStations = [
    { nodeStation: "Cervical superior", position: null, orientation: null },
    { nodeStation: "Submandibular", position: null, orientation: null },
    { nodeStation: "Cervical inferior", position: null, orientation: null },
    { nodeStation: "Supraclavicular", position: null, orientation: null },
    { nodeStation: "Occipital", position: null, orientation: null },
    { nodeStation: "Axilar medial", position: null, orientation: null },
    { nodeStation: "Axilar lateral", position: null, orientation: null },
    { nodeStation: "Inghinal", position: null, orientation: null },
  ];
const Page1 = ({ evaluation,stations = [],currentPage,totalPages}) => {
console.log(evaluation);
console.log(stations);

const findStation = (nodeStation) => stations && stations.data && stations.data.find(s => s.nodeStation === nodeStation);

    return (
        <div className={styles.page}  id="page">
            <div className={styles.antet}>
                <img className={styles.antetBgIcon} alt="" src="/antet-bg@2x.png" />
                <img className={styles.logoIcon} alt="" src="/LymphMedLogo.png" />
            </div>
            <div className={styles.content} >

                <div className={styles.reportHeader}>
                    <div className={styles.computerAidedDiagnostic}> computer aided diagnostic </div>
                    <div className={styles.reportLine} />
                    <div className={styles.patientInfo}>
                        <div className={styles.patientSerialNumberContainer}>
                            <span>{`Patient Serial Number: `}</span>
                            <b>{evaluation.patientSN}</b>
                        </div>
                        <div className={styles.dateDdMmContainer}>
                            <span>{`Date: `}</span>
                            <b>{`${evaluation?.timestamp?.split('T')[0]} ${evaluation?.timestamp?.split('T')[1]?.split('.')[0]}`}</b>
                        </div>
                    </div>
                    <div className={styles.reportLine} />
                </div>

                <div className={styles.imagingTests}>
                    
                    <div style={{ position: 'absolute', width: '0.4vh', left: '16.10%', top: '0', height: '100%', transformOrigin: '0 0', backgroundColor: '#b1dcea' }} />
                    <div className={styles.sectionTitle}>Imaging tests:</div>
                    <div style={{ position: 'relative', height: '100%', flexGrow: '1' }}>
                        <div className={styles.sectionNote}>
                            <div className={styles.notesTitle}>CT</div>
                            <i className={styles.notes}>{evaluation.ctNotes}</i>
                        </div>
                        <div style={{ position: 'relative',  width: '100%',marginBottom:'0.5%',marginTop:'0.5%', height: '0.4vh', backgroundColor: '#b1dcea' }} />
                        <div className={styles.sectionNote}>
                            <div className={styles.notesTitle}>MRI</div>
                            <i className={styles.notes}>{evaluation.mriNotes}</i>
                        </div>
                        <div style={{ position: 'relative', width: '100%',marginBottom:'0.5%',marginTop:'0.5%', height: '0.4vh',backgroundColor: '#b1dcea' }} />
                        <div className={styles.sectionNote}>
                            <div className={styles.notesTitle}>US</div>
                            <i className={styles.notes}>{evaluation.usNotes}</i>
                        </div>

                    </div>
                </div>

                <div className={styles.reportLine} />
                <ClinicalDiagnosisContainer diagnosisText="Clinical diagnosis:" diagnosisValue= {evaluation.clinicalDiagnosis}/>
                <div className={styles.reportLine} />
                <ClinicalDiagnosisContainer diagnosisText="Pathological results:" diagnosisValue= {evaluation.pathologicalResults}/>
                <div className={styles.reportLine} />

                <div className={styles.reportTable}>
                    <div className={styles.transducerPosition}>Transducer Position:</div>
                    <div className={styles.transducerTableLine} />
                    <div className={styles.tableHeader}>
                        <div className={styles.nodeStation}>Node Station:</div>
                        <div className={styles.position}>Position</div>
                        <div className={styles.orientation}>Orientation</div>
                    </div>

                    <div style={{ position: 'absolute', width: '0.4vh', left: '78.5%', top: '6.5vh', height: '95%', transformOrigin: '0 0', backgroundColor: '#b1dcea' }} />
                    <div style={{ position: 'absolute', width: '0.4vh', left: '89.5%', top: '6.5vh', height: '95%', transformOrigin: '0 0', backgroundColor: '#b1dcea' }} />
                    {allStations.map((station, index) => {
                    const matchedStation = findStation(station.nodeStation);
                    return (
                        <React.Fragment key={index}>
                            <ReportLine 
                                text={station.nodeStation}
                                position={matchedStation ? matchedStation.position : undefined}
                                orientation={matchedStation ? matchedStation.orientation : undefined}
                            />
                            {index !== allStations.length - 1 && <div className={styles.transducerTableLine} />}
                        </React.Fragment>
                    );
                })}
                </div>

                <div className={styles.reportLine} />
                <div className={styles.credit}> ©2023-2024 LymphMed-AI RO, All Rights Reserved </div>
                <div className={styles.pageNr}>{currentPage}/{totalPages}</div>
            </div>

        </div>
    );
};

export default Page1;
