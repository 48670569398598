import { useMemo } from "react";
import styles from "./TextField.module.css";

const TextField = ({ placeholder, patientSNTop, name, value, onChange }) => {
  const textFieldStyle = useMemo(() => ({
    top: patientSNTop, 
  }), [patientSNTop]);

  return (
    <div className={styles.textFieldContainer} style={textFieldStyle}>
      <input 
        type="text" 
        className={styles.inputField} 
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextField;
