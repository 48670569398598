import React from 'react';
import './BackButton.module.css';
import { useNavigate } from 'react-router-dom';

const backButtonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: '5%',
    top: '7%',
    width: '25px', 
    height: '25px'  
};


const BackButton = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <img 
        src="/BackArrow.svg" 
        alt="Back" 
        onClick={handleBack} 
        style={backButtonStyle} 
    />
    );
};

export default BackButton;
