import React, { useState, useEffect } from 'react';
import Page1 from "../components/Report/Page1";
import Page2 from "../components/Report/Page2";
import Page3 from "../components/Report/Page3";
import { getEvaluations, getStations } from '../api';
import styles from "./Report.module.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useLocation } from 'react-router-dom';

const Report = () => {
    const [evaluation, setEvaluation] = useState("");
    const [stations, setStations] = useState([]);
    const location = useLocation();
    const serialNumber = location.state?.serialNumber;
    const evaluationId = location.state?.evaluationId;
    const evaluationDate = location.state?.evaluationDate;

    console.log(serialNumber);
    console.log(evaluationId);
    console.log(evaluationDate);
    const calculateTotalPages = (stations) => {
        
        let totalCount = 1;
        totalCount += stations.length;
        stations.forEach(station => {
            if (station.originalStandardURL) totalCount += 1;
            if (station.originalDopplerURL) totalCount += 1;
            if (station.originalElastoURL) totalCount += 1;
        });

        return totalCount;
    };

    const totalPageCount = calculateTotalPages(stations.data || []);
    useEffect(() => {
        getEvaluations(serialNumber).then(data => {
            if (data !== undefined) {
                setEvaluation(data[0]);
                console.log(data[0]);
            }
        });
    }, [serialNumber]);

    useEffect(() => {

        getStations(evaluationId).then(data => {
            if (data !== undefined) {
                setStations(data);
                console.log(data);
            }
        });
    }, [evaluationId]);

    const downloadPdfDocument = async () => {
        const button = document.getElementById('downloadPdfButton');
        button.style.display = 'none';

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4'
        });

        const pages = document.querySelectorAll('[class*="_page_"]');

        console.log(pages);
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];

            const canvas = await html2canvas(page, {
                width: page.scrollWidth,
                height: page.scrollHeight,
                useCORS: true
            });
            const imgData = canvas.toDataURL('image/png');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            const scaleX = pdfWidth / imgProps.width;
            const scaleY = pdfHeight / imgProps.height;
            const scaleToFit = Math.min(scaleX, scaleY);

            const scaledWidth = imgProps.width * scaleToFit;
            const scaledHeight = imgProps.height * scaleToFit;

            if (i > 0) {
                pdf.addPage();
            }

            pdf.addImage(imgData, 'PNG', 0, 0, scaledWidth, scaledHeight);
        }

        pdf.save("download.pdf");
        button.style.display = 'block';
    };

    return (
        <div className={styles.reportContent} id="reportContent">
            <button id="downloadPdfButton" onClick={downloadPdfDocument}>Download PDF</button>
            <Page1 evaluation={evaluation} stations={stations} currentPage={1} totalPages={totalPageCount} />
            {stations && stations.data && stations.data.map((station, index) => {
                let currentPage = 2;
                for (let i = 0; i < index; i++) {
                    currentPage += (stations.data[i].originalStandardURL ? 1 : 0) +
                        (stations.data[i].originalDopplerURL ? 1 : 0) +
                        (stations.data[i].originalElastoURL ? 1 : 0) + 1;
                }
                return (
                    <React.Fragment key={`station-${index}`}>
                        <Page3 station={station} currentPage={currentPage++} totalPages={totalPageCount} />
                        {station.originalStandardURL && <Page2 imageType="Standard" station={station} currentPage={currentPage++} totalPages={totalPageCount} />}
                        {station.originalDopplerURL && <Page2 imageType="Doppler" station={station} currentPage={currentPage++} totalPages={totalPageCount} />}
                        {station.originalElastoURL && <Page2 imageType="Elasto" station={station} currentPage={currentPage} totalPages={totalPageCount} />}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default Report;
