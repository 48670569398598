import React, { useState } from 'react';
import styles from "./F3.module.css";
import { useLocation } from 'react-router-dom';
import EvaluationForm from "../components/Forms/EvaluationForm";
import BackButton from '../components/Buttons/BackButton';


const F3= () => {
  const location = useLocation();
  const serialNumber = location.state?.serialNumber;
  
  return (
    <div className={styles.Desktop}>
          <img className={styles.background} alt="" src="/background.png" />
          <img className={styles.logo} alt="" src="/LymphMedLogo.png" />
          <BackButton />
          <EvaluationForm serialNumber={serialNumber}/>
    </div>
  );
};

export default F3;
