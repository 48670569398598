import styles from "./ReportLine.module.css";

const ReportLine = ({ text, position, orientation  }) => {

  return (
    <div className={styles.container} >
      <div className={styles.nodeStation}>{text}</div>
      <div className={styles.nodePosition} >
        <div className={styles.position}>
          <div className={styles.checkboxText}>Left</div>
          <div className={styles.checkbox} >
            <div className={styles.checkboxOuter} />
            {position === "Left" && <div className={styles.checkboxInner} />}
          </div>
        </div>
        <div className={styles.position}>
          <div className={styles.checkboxText}>Right</div>
          <div className={styles.checkbox} >
            <div className={styles.checkboxOuter} />  
            {position === "Right" && <div className={styles.checkboxInner} />}
          </div>
        </div>
      </div>
      <div className={styles.nodeOrientation}>
        <div className={styles.position} >
          <div className={styles.checkboxText}>Saggital</div>
          <div className={styles.checkbox} >
            <div className={styles.checkboxOuter} />   
            {orientation === "Saggital" && <div className={styles.checkboxInner} />}
          </div>
        </div>
        <div className={styles.position} >
          <div className={styles.checkboxText}>Transversal</div>
          <div className={styles.checkbox} >
            <div className={styles.checkboxOuter} /> 
            {orientation === "Transversal" && <div className={styles.checkboxInner} />}
          </div>
        </div>
        <div className={styles.position}>
          <div className={styles.checkboxText}>Oblique</div>
          <div className={styles.checkbox} >
            <div className={styles.checkboxOuter} />
            {orientation === "Oblique" && <div className={styles.checkboxInner} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportLine;
