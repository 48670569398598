import PatientForm from "../components/Forms/PatientForm";
import styles from "./F21.module.css";

const F21 = () => {
  return (
    <div className={styles.Desktop}>
    <img className={styles.background} alt="" src="/background.png" />
    <img className={styles.logo} alt="" src="/LymphMedLogo.png" />
      <div className={styles.frame}>
        <PatientForm />
        <img className={styles.image} alt="" src="/Frame21.png" />
      </div>
    </div>
  );
};

export default F21;
