import DateField from "../components/Fields/DateField";
import UploadButton from "../components/Buttons/UploadButton";
import UploadComponent from "../components/UploadComponent";
import Dropdown from "../components/Dropdowns/Dropdown";
import PatientSN from "../components/PatientSN";
import styles from "./F4.module.css";
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FirstTab from "../components/Tabs/FirstTab";
import { createStation, evaluate } from '../api';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/Buttons/BackButton';

const F4 = () => {
    const location = useLocation();
    const serialNumber = location.state?.serialNumber;
    const evaluationId = location.state?.evaluationId;
    const [standardImage, setStandard] = useState(null);
    const [dopplerImage, setDoppler] = useState(null);
    const [elastoImage, setElasto] = useState(null);
    const navigate = useNavigate();

    const nodeStationOptions = [
        { value: "Occipital", label: "Occipital" },
        { value: "Cervical superior", label: "Cervical superior" },
        { value: "Submandibular", label: "Submandibular" },
        { value: "Cervical inferior", label: "Cervical inferior" },
        { value: "Supraclavicular", label: "Supraclavicular" },
        { value: "Axilar medial", label: "Axilar medial" },
        { value: "Axilar lateral", label: "Axilar lateral" },
        { value: "Inghinal", label: "Inghinal" }
    ];

    const positionOptions = [
        { value: "Left", label: "Left" },
        { value: "Right", label: "Right" }
    ];

    const orientationOptions = [
        { value: "Saggital", label: "Saggital" },
        { value: "Transversal", label: "Transversal" },
        { value: "Oblique", label: "Oblique" }
    ];
    const [resetUpload, setResetUpload] = useState(false);
    const [evaluationDate, setEvaluationDate] = useState("");

    const clearUploads = () => {
        setResetUpload(true);
        setTimeout(() => setResetUpload(false), 0);
    };

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        setEvaluationDate(today);
    }, []);

    const setStandardImage = (file) => {
        setStandard(file);
    }
    const setDopplerImage = (file) => {
        setDoppler(file);
    }
    const setElastoImage = (file) => {
        setElasto(file);
    }
    // State for dropdown values
    const [nodeStation, setNodeStation] = useState('');
    const [position, setPosition] = useState('');
    const [orientation, setOrientation] = useState('');

    // Handle dropdown changes
    const handleDropdownChange = (e) => {
        const { name, value } = e.target;
        if (name === 'nodeStation') setNodeStation(value);
        else if (name === 'position') setPosition(value);
        else if (name === 'orientation') setOrientation(value);
    };

    const handleSubmit = async (isEvaluate) => {
        if (!nodeStation || !position || !orientation) {
            alert('Please select all dropdown options.');
            return;
        }

        const files = [];
        if (standardImage) files.push(standardImage);
        if (dopplerImage) files.push(dopplerImage);
        if (elastoImage) files.push(elastoImage);

        if (files.length === 0) {
            alert('Please upload at least one image.');
            return;
        }

        try
         {
            
            await createStation(serialNumber, evaluationId, nodeStation, position, orientation, files);
            clearUploads();
            const fileInputs = document.querySelectorAll('input[type="file"]');
            fileInputs.forEach(input => input.value = '');

            if (!isEvaluate) {
                setNodeStation('');
                setPosition('');
                setOrientation('');
                setStandard(null);
                setDoppler(null);
                setElasto(null);
            }
            else {
                await evaluate(evaluationId);
                navigate('/F5', { state: { serialNumber: serialNumber, evaluationId: evaluationId ,evaluationDate: evaluationDate} });
            }
        } catch (error) {
            alert('Error creating station.');
            console.error(error);
        }
    };

    const handleEvaluate = () => {
        handleSubmit(true);
    };

    const handleNewStation = () => {
        handleSubmit(false);
    };

    return (
        <div className={styles.Desktop}>
            <img className={styles.background} alt="" src="/background.png" />
            <img className={styles.logo} alt="" src="/LymphMedLogo.png" />
            <BackButton />
            <div className={styles.form}>
                <div style={{ position: 'absolute', width: '50%', height: '10%', display: "flex" }}>
                    <FirstTab text="Upload Images" />
                </div>
                <div className={styles.evaluationDateParent}>
                    <div className={styles.evaluationDate}>Evaluation date:</div>
                    <DateField placeholder="DD/MM/YY*"
                        name="evaluationDate"
                        value={evaluationDate}
                        onChange={() => { }}
                        readonly={true} />
                </div>

                <PatientSN serialNumber={serialNumber} />

                <div className={styles.rectangleParent}>
                    <div className={styles.transducerPosition}>Transducer Position:</div>
                    <Dropdown
                        placeholder="Select Node Station*"
                        options={nodeStationOptions}
                        name="nodeStation"
                        value={nodeStation}
                        onChange={handleDropdownChange}
                        selectStyle={{ width: '272px' }}
                    />
                    <Dropdown
                        placeholder="Select Position*"
                        options={positionOptions}
                        name="position"
                        value={position}
                        onChange={handleDropdownChange}
                        selectStyle={{ width: '272px' }}
                    />
                    <Dropdown
                        placeholder="Select Orientation*"
                        options={orientationOptions}
                        name="orientation"
                        value={orientation}
                        onChange={handleDropdownChange}
                        selectStyle={{ width: '272px' }}
                    />
                </div>

                <div className={styles.component7Parent}>
                    <UploadComponent header="Standard" setImage={setStandardImage}  reset={resetUpload}/>
                    <UploadComponent header="Doppler" setImage={setDopplerImage}  reset={resetUpload}/>
                    <UploadComponent header="Elasto" setImage={setElastoImage}  reset={resetUpload}/>
                </div>

                <div className={styles.line} />
                <div style={{ display: 'flex', position: 'relative', top: '40%', flexDirection: 'row', justifyContent: 'flex-start', width: '40%', height: '10%' }}>
                    <UploadButton text="Evaluate" onClick={handleEvaluate} />
                    <UploadButton text="New Station" onClick={handleNewStation} />
                </div>
            </div>
        </div>
    );
};

export default F4;
