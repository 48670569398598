import React from 'react';
import styles from './PatientSN.module.css';  

function PatientSN({serialNumber}) {
    return (
        <div className={styles.group}>
            <div className={styles.line} />
            <div className={styles.text}>Patient SN :{serialNumber}</div>
            <div className={styles.line} />
        </div>
    );
}

export default PatientSN;
