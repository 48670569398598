import React from "react";
import styles from "./ResultsTab.module.css";

const ResultsTab = ({ text, onClick, disabled, isActive }) => {
  let backgroundColor;
  let color;
   if (isActive) {
    backgroundColor = '#82cbdb';
    color = '#003F5A'
  }
  const occipitalStyle = React.useMemo(() => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.5 : 1,
    backgroundColor: backgroundColor,
    color:color
  }), [disabled, isActive]);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <div className={styles.occipitalWrapper} onClick={handleClick} style={occipitalStyle}>
      <div className={styles.occipital}>
        {text}
      </div>
    </div>
  );
};

export default ResultsTab;
