import { Link } from 'react-router-dom';
import styles from './F1.module.css';
import DividedSection from '../components/Sections/DividedSection';
import StartButton from '../components/Buttons/StartButton';

const F1 = () => {
	return (
		<div className={styles.Desktop}>
		<img className={styles.background} alt="" src="/background.png" />
		<img className={styles.logo} alt="" src="/LymphMedLogo.png" />
		<div className={styles.frameParent}>
			<div className={styles.descriptionParent}>
				<DividedSection
					description="Description"
					paragraph1="Cancer is the second leading cause of death globally. If you’re diagnosed with cancer, one of the most important things you’ll learn is your cancer’s stage, a crucial knowledge for doctors to advise the best treatment options. TNM is a globally recognized standard for classification of malignant tumors, N representing the degree of the spread of the tumor in the regional lymph nodes. Therefore, it is crucial to accurately assess the lymphatic extension of the tumor, patients with lymph nodes metastasis having a higher risk for recurrence or death from cancer."
					paragraph2="LymphMED-AI is a computer aided diagnostic and follow-up system based on ultrasound (US) image analysis and AI for the metastasis assessment of superficial lymph nodes before and after treatment. It can improve clinical cancer staging workflow by quantifying lymph node characteristics for evaluation, follow-up and documentation being cost-effective, scalable, non-invasive, automated and accessible."
				/>
				<Link to="/F21"> <StartButton text="Start Evaluation" /> </Link>
			</div>
			<div className={styles.groupWrapper}>
				<img className={styles.frameChild} alt="" src="/Frame1.png" />
			</div>
		</div>
		</div>
	);
};
export default F1;
