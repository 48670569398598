import styles from './StartButton.module.css';

const StartButton = ({ text}) => {
    return (
        <div className={styles.startButton}>
            <div className={styles.startButton1}>
                <div className={styles.startEvaluationWrapper}>
                    <div className={styles.startEvaluation}>{text}</div>
                </div>
            </div>
        </div>
    );
};

export default StartButton;

