import DateField from "../components/Fields/DateField";
import UploadButton from "../components/Buttons/UploadButton";
import ResultsTab from "../components/Tabs/ResultsTab";
import ImageResult from "../components/ImageResult";
import PatientSN from "../components/PatientSN";
import ResultCard from "../components/ResultCard";
import FirstTab from "../components/Tabs/FirstTab";
import styles from "./F5.module.css";
import { getStations } from '../api';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/Buttons/BackButton';

const F5 = () => {

  const location = useLocation();
  const serialNumber = location.state?.serialNumber;
  const evaluationId = location.state?.evaluationId;
  const evaluationDate = location.state?.evaluationDate;
  const navigate = useNavigate();
  
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStationName, setSelectedStationName] = useState(null);
  const createReport = () => {
    navigate('/Report', { state: { serialNumber: serialNumber, evaluationId: evaluationId ,evaluationDate: evaluationDate} });
  };
  useEffect(() => {
    
    const fetchStations = async () => {
      try {
        const response = await getStations(evaluationId);
        setStations(response.data); 
        if (stations.length > 0) {
          setSelectedStationName(stations[0].nodeStation);
        }
        
      } catch (error) {
        console.error('Error fetching stations:', error);
      }
    };

    if (evaluationId) {
      fetchStations();
    }
  }, [evaluationId]); 


  useEffect(() => {
    const selectedStationData = stations.find(station => station.nodeStation === selectedStationName);
    setSelectedStation(selectedStationData);
  }, [selectedStationName, stations]);

  return (
    <div className={styles.Desktop}>
      <img className={styles.background} alt="" src="/background.png" />
      <img className={styles.logo} alt="" src="/LymphMedLogo.png" />
      <BackButton />
      <div className={styles.form}>
        <div style={{ position: 'absolute', width: '50%', height: '10%', display: "flex" }}>
          <FirstTab text="Evaluation" />
        </div>
        <div className={styles.evaluationDateParent}>
          <div className={styles.evaluationDate}>Evaluation date:</div>
          <DateField placeholder="DD/MM/YY*" 
                        name="evaluationDate"
                        value={evaluationDate}
                        onChange={() => { }}
                        readonly={true} />
        </div>

        <PatientSN serialNumber={serialNumber} />
        <div className={styles.line} />
        <div className={styles.resultsContainer}>
        <div className={styles.tabs}>
          <ResultsTab text="Occipital" onClick={() => setSelectedStationName('Occipital')} disabled={!stations.some(station => station.nodeStation === 'Occipital')} isActive={selectedStation === 'Occipital'}/>
          <ResultsTab text="Cervical superior" onClick={() => setSelectedStationName('Cervical superior')} disabled={!stations.some(station => station.nodeStation === 'Cervical superior')} isActive={selectedStation === 'Cervical superior'}/>
          <ResultsTab text="Submandibular" onClick={() => setSelectedStationName('Submandibular')} disabled={!stations.some(station => station.nodeStation === 'Submandibular')} isActive={selectedStation === 'Submandibular'}/>
          <ResultsTab text="Cervical inferior" onClick={() => setSelectedStationName('Cervical inferior')} disabled={!stations.some(station => station.nodeStation === 'Cervical inferior')} isActive={selectedStation === 'Cervical inferior'}/>
          <ResultsTab text="Supraclavicular" onClick={() => setSelectedStationName('Supraclavicular')} disabled={!stations.some(station => station.nodeStation === 'Supraclavicular')} isActive={selectedStation === 'Supraclavicular'}/>
          <ResultsTab text="Axilar  medial" onClick={() => setSelectedStationName('Axilar medial')} disabled={!stations.some(station => station.nodeStation === 'Axilar medial')} isActive={selectedStation === 'Axilar medial'}/>
          <ResultsTab text="Axilar lateral" onClick={() => setSelectedStationName('Axilar lateral')} disabled={!stations.some(station => station.nodeStation === 'Axilar lateral')} isActive={selectedStation === 'Axilar lateral'}/>
          <ResultsTab text="Inghinal" onClick={() => setSelectedStationName('Inghinal')} disabled={!stations.some(station => station.nodeStation === 'Inghinal')} isActive={selectedStation === 'Inghinal'}/>
        </div>
        <div className={styles.result}>
          <ImageResult text="Original Image" 
          standardUrl={selectedStation?.originalStandardURL}
          dopplerUrl={selectedStation?.originalDopplerURL}
          elastoUrl={selectedStation?.originalElastoURL}/>
          <ImageResult text="Proccesed Image" 
          standardUrl={selectedStation?.resultStandardURL}
          dopplerUrl={selectedStation?.resultDopplerURL}
          elastoUrl={selectedStation?.resultElastoURL}/>
          <ResultCard station = {selectedStation}/>
        </div>
        </div>

        <div style={{ display: 'flex', position: 'relative', top: '35%', flexDirection: 'row', justifyContent: 'flex-start', width: '30%' ,height:'10%'}}>
          <UploadButton text="Create Report"  onClick={createReport} />
        </div>
      </div>

    </div>
  );
};

export default F5;
