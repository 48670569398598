import ContactForm from "../components/ContactForm";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact-container"><div className="contact-heading-container"><p className="contact-us-text">
      Contact us<span className="highlighted-dot">.</span>
    </p>
      <p className="empower-text">Empower ultrasound images</p>
    </div>
      <ContactForm />
      <div className="contact-background">
        <div className="background-color-overlay" />
        <img className="background-image" alt="Background" src="/group-331@2x.png" />
        <div className="background-gradient-overlay" />
      </div>
    </div>
  );
};

export default Contact;
