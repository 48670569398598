import styles from './DividedSection.module.css';

const DividedSection = ({ description, paragraph1, paragraph2 }) => {
  return (
    <div className={styles.description}>
      <div className={styles.dividerTopParent}>
        <div className={styles.dividerTop}>
          <div className={styles.descriptionWrapper}>
            <div className={styles.description1}>{description}</div>
          </div>
          <div className={styles.dividerTopInner}>
            <img className={styles.frameChild} alt="" src="/DividerTop.png" />
          </div>
        </div>
        <div className={styles.text}>
          <div className={styles.cancerIsTheContainer}>
            <p className={styles.cancerIsThe}>{paragraph1}</p>
            <p className={styles.cancerIsThe}>&nbsp;</p>
            <p className={styles.cancerIsThe}>{paragraph2}</p>
          </div>
        </div>
        <div className={styles.dividerBottom}>
          <img className={styles.frameChild} alt="" src="/DividerBottom.png" />
        </div>
      </div>
    </div>
  );
};

export default DividedSection;

