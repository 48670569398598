import UploadImageButton from "./Buttons/UploadImageButton";
import styles from "./UploadComponent.module.css";
import React, { useState,useEffect } from 'react';

const UploadComponent = ({ header, setImage, reset   }) => {

  const [image, setLocal] = useState(null);
  const handleImageChange = (e) => {
     if (e.target.files && e.target.files[0]) {
      console.log("Uploading");
      const file = e.target.files[0];
      const newFileName = header.toLowerCase(); 
      const newFile = new File([file], newFileName, { type: file.type });
      setImage(newFile);
      setLocal(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (reset) {
      setLocal(null); 
      setImage(null); 
    }
  }, [reset, setImage]);
  return (
    <div className={styles.container}>
      <div style={{position:'relative', width:'40%', height:'100%' }}>
      {/* <i className={styles.description}>{description}</i> */}
      <div className={styles.header}>{header}</div>
      </div>
      <div style={{position:'relative', width:'60%', height:'100%', backgroundColor:'#136681' }}>
      <UploadImageButton text={header} onImageChange={handleImageChange} image={image} />
      </div>
    </div>
  );
};

export default UploadComponent;
