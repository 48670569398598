import React from "react";
import "./ReadMoreButton.css";

const ReadMoreButton = ({
  buttonText,
  buttonLinkText,
  onReadMoreButtonClick
}) => {
  return (
    <button className="read-more-button" onClick={onReadMoreButtonClick}>
      <span className="read-more">{buttonText}</span>
      <img
        className="vuesaxlineararrow-right-icon"
        alt="Arrow Icon"
        src={buttonLinkText}
      />
    </button>
  );
};

export default ReadMoreButton;
