import styles from "./GoToPatientButton.module.css";

const GoToPatientButton = ({ text }) => {
  const handleClick = () => {
    console.log("Go To Patient button clicked");
  };

  return (
    <button className={styles.goToPatientButton} onClick={handleClick}>
      <img className={styles.buttonBackground} alt="" src="/rectangle-24@2x.png" />
      <div className={styles.buttonContent}>
        {/* <img className={styles.buttonIcon} alt="" src="/group-61@2x.png" /> */}
        <div className={styles.buttonText}>{text}</div>
      </div>
    </button>
  );
};

export default GoToPatientButton;
