import "./LEDD2.css";
const LEDD2 = () => {
  return (
    <div className="ledd2">
 <img alt="" src="/page/LEDD2.png"
        style={{
          display: "block",
          width: "100%"
        }} />
    </div>
  );
};

export default LEDD2;
