import React, { useEffect,useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Nav.css";

const Nav = () => {
    const navigate = useNavigate();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        if (isMobileMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [isMobileMenuOpen]);

    const onAboutUsTextClick = useCallback(() => {
        navigate("/");
        setActiveMenu("aboutUs");
        setMobileMenuOpen(false);
    }, [navigate]);

    const onGrantsTextClick = useCallback(() => {
        navigate("/grants");
        setActiveMenu("grants");
        setMobileMenuOpen(false);
    }, [navigate]);

    const onScientificResearchTextClick = useCallback(() => {
        navigate("/scientific-research");
        setActiveMenu("scientific-research");
        setMobileMenuOpen(false);
    }, [navigate]);

    const onPressTextClick = useCallback(() => {
        navigate("/press");
        setActiveMenu("press");
        setMobileMenuOpen(false);
    }, [navigate]);

    const onContactTextClick = useCallback(() => {
        navigate("/contact");
        setActiveMenu("contact");
        setMobileMenuOpen(false);
    }, [navigate]);

    const onSolutionsTextClick = useCallback(() => {
        navigate("/ledd");
        setActiveMenu("ledd");
        setMobileMenuOpen(false);
    }, [navigate]);

    const onF1TextClick = useCallback(() => {
        navigate("/F1");
        setActiveMenu("F1");
        setMobileMenuOpen(false);
    }, [navigate]);
    
    return (
        <header className="nav">
            <img className="aimed-logo-1-icon" alt="Aimed Logo" src="/aimedlogo-1@2x.png" />

            <img className="mobile-menu-icon" onClick={toggleMobileMenu} src="/three-line-menu-icon.jpg" alt="Menu Icon" />

            <nav className={`menu ${isMobileMenuOpen ? 'active' : ''}`}>
                <div className={`grants ${activeMenu === 'aboutUs' ? 'active' : ''}`} onClick={onAboutUsTextClick}>About</div>
                <div className={`grants ${activeMenu === 'grants' ? 'active' : ''}`} onClick={onGrantsTextClick}>Grants</div>
                <div className={`grants ${activeMenu === 'scientific-research' ? 'active' : ''}`} onClick={onScientificResearchTextClick}>Research</div>
                <div className={`grants ${activeMenu === 'press' ? 'active' : ''}`} onClick={onPressTextClick}>Press</div>
                <div className={`grants ${activeMenu === 'contact' ? 'active' : ''}`} onClick={onContactTextClick}>Contact</div>
                <div className={`grants ${activeMenu === 'ledd' ? 'active' : ''}`} onClick={onSolutionsTextClick}>Solutions</div>
                <div className={`grants ${activeMenu === 'f1' ? 'active' : ''}`} onClick={onF1TextClick}>LymphAI</div>
            </nav>

        </header>
    );
};

export default Nav;
