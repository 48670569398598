import styles from "./Page3.module.css";
import ReportLine from "./ReportLine";

const Page3 = ({station,currentPage,totalPages}) => {
    console.log(station);
    return (
        <div className={styles.page} id="page">
            <div className={styles.antet}>
                <img className={styles.antetBgIcon} alt="" src="/antet-bg@2x.png" />
                <img className={styles.logoIcon} alt="" src="/LymphMedLogo.png" />
            </div>
            <div className={styles.content} >
                <div className={styles.reportHeader}>
                    <div className={styles.reportLine} />
                    <div className={styles.patientInfo}>
                        <div className={styles.patientSerialNumberContainer}>
                            <span>{`Patient Serial Number: `}</span>
                            <b>{station.patientSN}</b>
                        </div>
                        <div className={styles.dateDdMmContainer}>
                            <span>{`Date: `}</span>
                            <b>{`${station.timestamp.split('T')[0]} ${station.timestamp.split('T')[1].split('.')[0]}`}</b>
                        </div>
                    </div>
                    <div className={styles.reportLine} />
                </div>

                <div className={styles.header1}>Medical Indicators</div>
                <div className={styles.reportLine} />
                <div className={styles.reportTable}>
                    <div className={styles.tableHeader}>
                        <div className={styles.nodeStation}>Node Station:</div>
                        <div className={styles.position}>Position</div>
                        <div className={styles.orientation}>Orientation</div>
                    </div>
                    <div className={styles.reportLineLight} />
                    <ReportLine text={station.nodeStation}
                                position={station.position}
                                orientation={station.orientation}/>
                    <div className={styles.reportLineLight} />
                </div>
                <div className={styles.heading2}>Standard:</div>
                <div className={styles.reportLineLight} />
                <div className={styles.heading3}>Shape parameters:</div>
                <div className={styles.reportLineLight} />
                <div className={styles.line}>
                    <div className={styles.halfLine1}>
                        <div className={styles.halfLine1Text}>Width:</div>
                        <div className={styles.halfLine1Value}>{station.width}</div>
                        <div className={styles.halfLine1Text}>Length:</div>
                        <div className={styles.halfLine1Value}>{station.length}</div>
                    </div>
                    <div className={styles.halfLine2}>
                        <div className={styles.halfLine2Text}>Contour Variability:</div>
                        <div className={styles.halfLine2Value}>{station.variability}</div>
                    </div>
                </div>

                <div className={styles.reportLineLight} />
                <div className={styles.line}>
                    <div className={styles.halfLine1}>
                        <div className={styles.halfLine1Text}>Area:</div>
                        <div className={styles.halfLine1Value}>{station.area}</div>
                        <div className={styles.halfLine1Text} />
                        <div className={styles.halfLine1Value} />
                    </div>
                    <div className={styles.halfLine2}>
                        <div className={styles.halfLine2Text}>Contour Sharpness:</div>
                        <div className={styles.halfLine2Value}>{station.sharpness}</div>
                    </div>
                </div>
                
                <div className={styles.reportLineLight} />

                <div className={styles.heading2}>Doppler:</div>
                <div className={styles.reportLineLight} />
                <div className={styles.line}>
                    <div className={styles.halfLine2}>
                    <div className={styles.halfLine2Text}>Vascularity Ratio:</div>
                        <div className={styles.halfLine2Value}>{station.vascularityRatio}</div>
                    </div>
                    <div className={styles.halfLine2}>
                        <div className={styles.halfLine2Text}>Vascularity Position:</div>
                        <div className={styles.halfLine2Value}>{station.vascularityPosition}</div>
                    </div>
                </div>
                <div className={styles.reportLineLight} />

                <div className={styles.heading2}>Elasto:</div>
                <div className={styles.reportLineLight} />
                <div className={styles.line}>
                    <div className={styles.halfLine3}>
                    <div className={styles.halfLine3Text}>Red:</div>
                        <div className={styles.halfLine3Value}>{station.red}</div>
                    </div>
                    <div className={styles.halfLine3}>
                    <div className={styles.halfLine3Text}>Green:</div>
                        <div className={styles.halfLine3Value}>{station.green}</div>
                    </div>
                    <div className={styles.halfLine3}>
                    <div className={styles.halfLine3Text}>Blue:</div>
                        <div className={styles.halfLine3Value}>{station.blue}</div>
                    </div>
                </div>

                <div className={styles.reportLine} />
                <div className={styles.credit}> ©2023-2024 LymphMed-AI RO, All Rights Reserved </div>
                <div className={styles.pageNr}>{currentPage}/{totalPages}</div>
            </div>
        </div>
    );
};

export default Page3;
