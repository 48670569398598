import "./ContactForm.css";

const ContactForm = () => {
  return (
    <div className="form-container">
      <div className="contact-info">
        <h1>Contact information</h1>
        <p>AIMed Soft Solutions</p>
        <div className="address">
          <p>Augustin Presecan no 8, Suite 14</p>
          <p>Cluj-Napoca, Romania</p>
          <p>400505</p>
        </div>
        <img className="icon" alt="mail-icon" src="/mail@2x.png" />
        <img className="icon" alt="location-icon" src="/location@2x.png" />
        <p>office@ai-med.ro</p>
      </div>

      <div className="mail-form">
        <h1>Send a message</h1>

        <div className="input-group">
          <input type="text" placeholder="First name *" required />
          <input type="text" placeholder="Last name *" required />
        </div>

        <div className="input-group">
          <input type="text" placeholder="Email *" required />
          <input type="text" placeholder="Phone number" />
        </div>

        <input type="text" placeholder="Institution / Company" />
        <input type="text" placeholder="Country *" required />
        <input type="text" placeholder="How did you hear about us ?" />
        <textarea placeholder="Message" />

        <div className="button-container">
          <button className="send-button">
            Send <img className="arrow-icon" alt="send-arrow" src="/vuesaxlineararrowright2.svg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
