import "./Press.css";
const Press = () => {
  return (
    <div className="press">
    <img alt="" src="/page/Press.png"
        style={{
          display: "block",
          width: "100%"
        }} />
    </div>
  );
};

export default Press;
