import React from 'react';
import styles from "./SecondTab.module.css";

const SecondTab = ({ text, isActive, onClick, icon }) => {
  
  const imgSrc = isActive ? "/second_tab_active.png" : "/second_tab_inactive.png";
  const textStyle = {
    color: isActive ? "#003f5a" : "#39a3ba", 
    fontWeight: isActive ? 500 : "normal"  
  };

  return (
    <div className={styles.tabContainer} onClick={onClick}>
        <div className={styles.textContainer}>
          <div className={styles.tabText} style={textStyle}>{text}</div>
          {icon && <img className={styles.iconImage} alt="" src={icon} />}
        </div>
        <img className={styles.backgroundImage} alt="" src={imgSrc} />
    </div>
  );
};

export default SecondTab;
