
import styles from "./GroupComponent1.module.css";

const GroupComponent1 = ({ text, value }) => {

  return (
    <div className={styles.line}>
      <div className={styles.description} >
        {text}
      </div>
      <div className={styles.blankLine} />
      <div className={styles.valueWrap} >
        <i className={styles.value}>{value}</i>
      </div>
    </div>
  );
};

export default GroupComponent1;
