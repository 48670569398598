import UploadButton from "../Buttons/UploadButton";
import styles from "./EvaluationForm.module.css";
import FirstTab from "../Tabs/FirstTab";
import SecondTab from "../Tabs/SecondTab";
import EvaluationLine from '../EvaluationLine'
import PatientSN from '../PatientSN'
import DateField from "../Fields/DateField";
import React, { useState,useEffect } from 'react';
import { createEvaluation,getEvaluations } from '../../api';
import { useNavigate } from 'react-router-dom';


const EvaluationForm = ({ serialNumber }) => {
  const [activeTab, setActiveTab] = useState('first');
  const [clinicalDiagnosisNotes, setClinicalDiagnosisNotes] = useState('');
  const [pathologicalResultsNotes, setPathologicalResultsNotes] = useState('');
  const [mriNotes, setMriNotes] = useState('');
  const [ctNotes, setCtNotes] = useState('');
  const [usNotes, setUsNotes] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [evaluationId, setEvaluationId] = useState('');

  const [evaluationDate, setEvaluationDate] = useState('');
  const [evaluations, setEvaluations] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setEvaluationDate(today);
}, []);

  useEffect(() => {
    if (activeTab === 'second') 
    {
      getEvaluations(serialNumber).then(data => {
        if (data !== undefined) 
        {
          const dates = data.map(evaluation => evaluation.date);
          
          setEvaluations(data);
          if (dates.length > 0) {
            setSelectedDate(dates[0]); 
            updateFieldsForDate(dates[0]);
          }
          setIsReadOnly(true);
        }
      });
    } 
    else
    {
      setIsReadOnly(false);
    }
  }, [activeTab, serialNumber]);

  useEffect(() => {
    if (selectedDate) {
      updateFieldsForDate(selectedDate);
    }
  }, [selectedDate]);

  function formatDate(isoString) {
    const date = new Date(isoString);

    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    let day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

  const updateFieldsForDate = (date) => {
    const evaluation = evaluations.find(e => e.date === date);
    if (evaluation) {
      setMriNotes(evaluation.mriNotes);
      setCtNotes(evaluation.ctNotes);
      setUsNotes(evaluation.usNotes);
      setClinicalDiagnosisNotes(evaluation.clinicalDiagnosis);
      setPathologicalResultsNotes(evaluation.pathologicalResults);
      setEvaluationId(evaluation.id);
      setEvaluationDate(formatDate(evaluation.date));
      console.log(evaluation);
    }
  };

  const handleUploadClick = async () => {
    
      try 
      {
        if (activeTab === 'first') 
        {
          var apiResponse = await createEvaluation(ctNotes,mriNotes,usNotes,serialNumber,clinicalDiagnosisNotes,pathologicalResultsNotes);
          
          if (apiResponse !== undefined) 
          {
            setEvaluationId(apiResponse); 
            navigate('/F4', { state: { serialNumber: serialNumber, evaluationId: apiResponse } });
          } 
        } 
        else if (activeTab === 'second') 
        {
          navigate('/F5', { state: { serialNumber: serialNumber, evaluationId: evaluationId ,evaluationDate:evaluationDate } });
        }
      } 
      catch (error) 
      {
        
      }
  };

  return (
    <div>
      <div className={styles.form}>

        <div style={{ position: 'absolute', width: '50%', height: '10%',display:"flex"}}>
          <FirstTab text="New Evaluation" isActive={activeTab === 'first'} onClick={() => { setActiveTab('first'); }} />
          <SecondTab text="Past Evaluation" isActive={activeTab === 'second'} onClick={() => { setActiveTab('second'); }} />
        </div>

        <div className={styles.evaluationDateParent}>
          <div className={styles.evaluationDate}>Evaluation date:</div>
          <DateField placeholder="DD/MM/YY*"
                        name="evaluationDate"
                        value={evaluationDate}
                        onChange={() => { }}
                        readonly={true} />
        </div>

        <PatientSN serialNumber={serialNumber} />
        
        <div className={styles.rectangleContainer}>
          <div className={styles.imagingTests}>Imaging Tests:</div>
          <EvaluationLine text="Magnetic Resonance (MRI)" notes={mriNotes} setNotes={setMriNotes} isReadOnly={isReadOnly}/>
          <EvaluationLine text="Computed tomography (CT)" notes={ctNotes} setNotes={setCtNotes} isReadOnly={isReadOnly}/>
          <EvaluationLine text="UltraSound Image (US)" notes={usNotes} setNotes={setUsNotes} isReadOnly={isReadOnly}/>
        </div>
        <div style={{ position: 'relative', top: '25%', left: '-0.06rem', borderTop: '2px solid #82cbdb', width: '100%', height: '2px' }} />

        <div className={styles.rectangleParent}>
          <div className={styles.clinicalDiagnosis}>Clinical Diagnosis:</div>
          <textarea
            className={styles.notesTextArea}
            value={clinicalDiagnosisNotes}
            onChange={(e) => setClinicalDiagnosisNotes(e.target.value)}
            placeholder="Notes*"
            readOnly={isReadOnly}
          />
        </div>
        
        <div style={{ position: 'absolute', top: '60%', left: '50%', borderRight: '2px solid #82cbdb', width: '2px', height: '20%' }} />

        <div className={styles.rectangleGroup}>
          <div className={styles.pathologicalResults}> Pathological Results: </div>
          <textarea
            className={styles.notesTextArea}
            value={pathologicalResultsNotes}
            onChange={(e) => setPathologicalResultsNotes(e.target.value)}
            placeholder="Notes*"
            readOnly={isReadOnly}
          />
        </div>
        
        <div style={{ position: 'absolute', top: '80%', left: '-0.06rem', borderTop: '2px solid #82cbdb', width: '100%', height: '2px' }} />

        <div style={{ display: 'flex', position: 'absolute', top: '85%', flexDirection: 'row', justifyContent: 'flex-start', width: '25%',height:'15%'}}>
          <UploadButton text={activeTab === 'first' ? 'Upload' : 'View Evaluation'} onClick={handleUploadClick} />
        </div>

      </div>

    </div>
  );
};

export default EvaluationForm;
