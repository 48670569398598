import axios from 'axios';

const API_BASE_URL = 'https://function-aimed-backend.azurewebsites.net/api/';

// Function to create a patient
export const createPatient = async (firstName, lastName, serialNumber, gender, dob) => {
    try {
        const response = await axios.post(`${API_BASE_URL}CreatePatient`, {
            firstName,
            lastName,
            serialNumber,
            gender,
            dob
        });
        
    } catch (error) {
        console.error("Error creating patient:", error);
        return error.response.data;
    }
};

// Function to get a patient
export const getPatient = async (serialNumber) => {
    try {
        const response = await axios.get(`${API_BASE_URL}GetPatient?serialNumber=${serialNumber}`);
        console.log("Get Patient Response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error getting patient:", error);
    }
};

// Function to create an evaluation
export const createEvaluation = async (ct, mri, us, sn, cd, pr) => {
    try {
        const response = await axios.post(`${API_BASE_URL}CreateEvaluation`, {
            CT: ct,
            MRI: mri,
            US: us,
            SN: sn,
            CD: cd,
            PR: pr
        });
        
        console.log(response);
        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error("Error creating evaluation:", error);
    }
};

// Function to get evaluations
export const getEvaluations = async (serialNumber) => {
    try {
        const response = await axios.get(`${API_BASE_URL}GetEvaluations?serialNumber=${serialNumber}`);
        return response.data;
    } catch (error) {
        console.error("Error getting evaluations:", error);
    }
};

// Function to create a station
export const createStation = async (patientSN, evaluationId, nodeStation, position, orientation, files) => {
    try {
        const formData = new FormData();
        formData.append('PatientSN', patientSN);
        formData.append('EvaluationId', evaluationId);
        formData.append('NodeStation', nodeStation);
        formData.append('Position', position);
        formData.append('Orientation', orientation);

        // Append files
        files.forEach((file, index) => {
            formData.append(file.name, file);
        });

        const response = await axios.post(`${API_BASE_URL}CreateStation`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log(response);
        console.log("Create Station Response:", response.data);
    } catch (error) {
        console.error("Error creating station:", error);
    }
};

export const evaluate = async (evaluationId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}Evaluate?evaluationId=${evaluationId}`);
        return response;
    } catch (error) {
        console.error("Error getting stations:", error);
    }
};
// Function to get stations
export const getStations = async (evaluationId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}GetStations?evaluationId=${evaluationId}`);
        return response;
    } catch (error) {
        console.error("Error getting stations:", error);
    }
};
