import styles from "./Dropdown.module.css";

const Dropdown = ({ placeholder, options, name, value, onChange, containerStyle, selectStyle }) => {
  return (
    <div className={styles.dropdownContainer} style={containerStyle}>
        <select 
          className={styles.dropdownSelect}
          style={selectStyle}
          value={value}
          onChange={onChange}
          name={name}
        >
          <option value="" disabled hidden>{placeholder}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
    </div>
  );
};


export default Dropdown;

