import styles from "./ImageResult.module.css";
import React, { useState } from 'react';

const ImageResult = ({ text , standardUrl, dopplerUrl, elastoUrl}) => {
  const [selectedOption, setSelectedOption] = useState(''); 
  const defaultImageUrl = "/group-118@2x.png";

  const getImageUrl = () => {
    switch (selectedOption) {
      case 'Doppler US':
        return dopplerUrl || defaultImageUrl;
      case 'Standard US':
        return standardUrl || defaultImageUrl;
      case 'Elasto US':
        return elastoUrl || defaultImageUrl;
      default:
        return defaultImageUrl;
    }
  };

  const isDefaultImage = getImageUrl() === defaultImageUrl;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headertext}>
          {text}
        </div>
        <div className={styles.headeroptions}> 
            <div className={styles.headeroption} onClick={() => setSelectedOption('Standard US')}>Standard US</div>
           <div className={styles.line} />
            <div className={styles.headeroption} onClick={() => setSelectedOption('Doppler US')}>Doppler US</div>
            <div className={styles.line} />
            <div className={styles.headeroption} onClick={() => setSelectedOption('Elasto US')}>Elasto US</div>
        </div>
      </div>
      <div className={styles.imageResult}>
        {!isDefaultImage ? (
          <img className={styles.fullimage} alt={text} src={getImageUrl()} />
        ) : (
          <div className={styles.frame}>
            <img className={styles.frameInner} alt={text} src={getImageUrl()} />
            <div className={styles.standardUs1}>Select Station</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageResult;
