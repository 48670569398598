import styles from "./UploadButton.module.css";

const UploadButton = ({text,onClick}) => {

  return (
    <button className={styles.uploadButton} onClick={onClick}>
      <img className={styles.backgroundImage} alt="" src="/rectangle-24@2x.png" />
      <div className={styles.textWrapper}>
        <div className={styles.text}>{text}</div>
      </div>
    </button>
  );
};

export default UploadButton;
