import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        {/* Section 1 - Mission Statement */}
        <div className="mission-statement">
          Our mission is to provide higher quality care for patients through
          AI and ultrasound based solutions.
        </div>

        {/* Section 2 - Menu items */}
        <div className="menu-items">
          <div className="header">Company</div>
          <div>About us</div>
          <div>Projects</div>
          <div>Scientific research</div>
          <div>Press</div>
          <div>Contact</div>
          <div className="header">Solutions</div>
          <div>LEDD</div>
        </div>

        {/* Section 3 - Follow Us */}
        <div className="follow-us">
          <div className="header">Follow Us</div>
          <div>Facebook</div>
          <div>Linkedin</div>
          <div>Twitter</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
